import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { applyPromo, closeInvoice, findEvent, getProducts, refreshInvoice, updateEvent, refreshCusto } from '../api'
import Footer from '../components/Footer'
import PayEvent from '../components/PayEvent'
import Menu from '../components/UI/Menu'
import Button from '../components/UI/Button'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Option from '../components/Option'
import Loader from '../components/UI/Loader'
import Title from '../components/UI/Title'
import Logistic from '../components/Logistic'
import Custo from '../components/Custo'
import { language } from '../utils'
import { FiHelpCircle, FiImage } from 'react-icons/fi'
import BrandIcon from '../components/UI/BrandIcon'
import Price from '../components/UI/Price'
import ErrorPage from '../components/UI/ErrorPage'
import DatePicker from '../components/DatePicker'
moment.locale(language())

const [instagram, facebook, youtube] = ['https://www.instagram.com/_smileybox/', 'https://www.facebook.com/smileybox.bornephoto', 'https://www.youtube.com/channel/UCrO4rwo8HASvO-HHJMgh6Cg']

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/event\/(\d+)_(.+)/) || [])
const eventId = match[1]
const token = match[2]

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_TOKEN)

const payTrigger = {}

export default function Event() {
  const [showHelp, setShowHelp] = useState(false)
  const [event, setEvent] = useState(null)
  const [datePicker, setDatePicker] = useState()
  const [products, setProducts] = useState([])
  const options = products && products.filter(p => p.upsale)
  const shippings = products && products.filter(p => p.shipping)

  useEffect(() => {
    if (event && (event.stripeCache || event.stripeCache === false)) return
    if (event && event.error) return
    if (products.length === 0)
      getProducts().then(p => setProducts(p))
    findEvent(eventId, token).then(e => setEvent(e))
  }, [event])

  if (event && event.error) return <ErrorPage message={event.message} />
  if (event === null || event.stripeCache === null) return <Loader />

  const upcoming = event && event.stripeCache && event.stripeCache.upcoming
  const invoices = event.stripeCache && event.stripeCache.invoices || []
  const current = event.stripeCache && (upcoming || invoices.find(i => i.status === 'draft') || invoices.find(i => i.status === 'open'))
  const paidInvoice = event.stripeCache && invoices.find(i => i.status === 'paid')

  const loading = async () => {
    if (current) current.loading = true
    setEvent({ ...event })
  }
  const unloading = async () => {
    if (current) current.loading = false
    setEvent({ ...event })
  }
  const tryPromo = async code => {
    try {
      loading()
      return setEvent(await applyPromo(event, code))
    } catch (error) {
      unloading()
      return Promise.reject(error)
    }
  }
  const finalize = async () => {
    return closeInvoice(event)
  }
  const onCreaSaved = async crea => {
    return setEvent({ ...event, customization: crea })
  }
  const succeeded = async () => {
    loading()
    return setEvent(await refreshInvoice(event))
  }
  const addProduct = async product => {
    product.active = true
    loading()
    return setEvent(await updateEvent(event, { products: [...event.products.map(p => p.id), product.id] }))
  }
  const removeProduct = async product => {
    product.active = false
    loading()
    return setEvent(await updateEvent(event, { products: event.products.filter(p => p.id !== product.id).map(p => p.id) }))
  }
  const setPickup = async pickup => {
    return setEvent(await updateEvent(event, { pickup }))
  }
  const setAddress = async address => {
    return setEvent(await updateEvent(event, { address }))
  }
  const changeDate = async () => {
    const { date } = await datePicker({})
    if (date)
      setEvent(await updateEvent(event, { date }))
  }

  return (
    <>
      <Menu showHelp={showHelp} setShowHelp={setShowHelp} instagram={instagram} facebook={facebook} youtube={youtube}>
        {current && !(paidInvoice || current.pre_payment_credit_notes_amount > 0) &&
          <>
            <div>Confirmez au plus vite votre Smiley Box {event.date ? `pour le ${moment(event.date).format('DD/MM/YYYY')}` : ''}</div>
            <Button className="mt-2 lg:mt-0 lg:ml-4" variant="white" onClick={() => payTrigger.click()}>Réglez&nbsp;<Price price={current.amount_remaining} loading={current.loading} /></Button>
          </>
        }
        {!current && paidInvoice &&
          <>Votre réservation est confirmée {event.date ? `pour le ${moment(event.date).format('DD/MM/YYYY')}` : ''}</>
        }
        {current && (paidInvoice || current.pre_payment_credit_notes_amount > 0) &&
          <div>Votre réservation est confirmée, mais il vous reste un solde de&nbsp;<Price price={current.amount_remaining} loading={current.loading} />&nbsp;à régler</div>
        }
      </Menu>
      <DatePicker handler={setDatePicker} />
      <div className="flex flex-col justify-center items-center w-full mx-auto max-w-screen-lg lg:mt-16 py-10 lg:py-20 px-2 lg:px-0">
        <Title className="mb-12">Votre tableau de bord Smiley Box</Title>
        <div className="flex justify-between flex-wrap -mx-2 lg:-mx-5">
          <div className="flex justify-center items-center bg-blue rounded shadow-md text-white p-4 lg:px-6 m-2 lg:m-5 w-full lg:w-2/3+m">
            <img src="/booth.png" className="h-32 lg:h-40 mr-4 lg:mr-6 -mb-4" />
            <div className="flex-grow">
              <div className="text-2xl lg:text-3xl">Welcome {event.firstname}</div>
              <div className="text-base font-thin leading-tight">
                Vous trouverez sur cet espace le suivi de votre réservation Smiley Box, du paiement jusqu'à la galerie photo de votre événement.
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center bg-white rounded shadow-md text-gray-600 py-4 px-6 m-2 lg:m-5 w-full lg:w-1/3+m">
            <img src="/logo-mini.svg" className="h-16 mr-4" />
            <div className="flex-grow">
              <div className="text-2xl lg:text-3xl">Le jour J</div>
              <div className="text-base font-thin">
                {event.date
                  ? `Votre événement a lieu ${moment(event.date).fromNow()}`
                  : `Votre date n'est pas encore définie `
                }
              </div>
              <div className="text-xl mt-1 text-pink">
                {event.date
                  ? moment(event.date).format('DD/MM/YYYY')
                  : <Button onClick={changeDate}>Définir une date</Button>
                }
              </div>
            </div>
          </div>
          {event.stripeCache && options.map(o =>
            <Option key={o.id} product={o} event={event} addProduct={addProduct} removeProduct={removeProduct} />)
          }
          {event.stripeCache &&
            <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }} >
              <PayEvent event={event} upcoming={upcoming} tryPromo={tryPromo} finalize={finalize} succeeded={succeeded} payTrigger={payTrigger} />
            </Elements>
          }
          <Logistic event={event} setPickup={setPickup} setAddress={setAddress} shippings={shippings} />
          <Custo customization={event.customization} saved={onCreaSaved} />
          {!event.stripeCache &&
            <div className="w-1/3+sm lg:w-1/3+m m-2 lg:m-5" />
          }
          <div className="flex justify-center items-center bg-white rounded shadow-md text-gray-600 py-4 px-6 m-2 lg:m-5 w-full lg:w-1/3+m">
            <BrandIcon Icon={FiHelpCircle} className="relative mr-4 h-16 w-16 flex justify-center text-black flex-shrink-0" />
            <div className="flex-grow">
              <div className="text-3xl">Aide</div>
              <div className="text-base font-thin leading-tight">
                Nous sommes disponibles au <strong>+33&nbsp;1&nbsp;84&nbsp;60&nbsp;61&nbsp;64</strong>.
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center bg-blue rounded shadow-md text-white py-4 px-6 m-2 lg:m-5  w-full lg:w-2/3+m">
            <FiImage className="h-24 w-24 stroke-1 mr-6" />
            <div className="flex-grow">
              <div className="text-3xl">Galerie photo</div>
              {(event.date && moment(event.date).isBefore(moment()) && event.liveEvent)
                ? <div className="flex">
                  <Button variant="white" className="mt-2 mr-2" href={`https://live.wizito.com/galleries/${event.liveEvent.gallery.url_name}`}>Lien publique</Button>
                  <Button variant="white" className="mt-2" href={`https://live.wizito.com/galleries/${event.liveEvent.gallery.url_name}/admin/${event.liveEvent.gallery.token}`}>Administration</Button>
                </div>
                : <div className="text-base font-thin">Retrouvez ici vos souvenirs dès que nous recevons votre borne</div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer showHelp={() => setShowHelp(true)} />
    </>
  )
}
