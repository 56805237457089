import React, { useState } from 'react'
import { FiCheck, FiMoreHorizontal, FiPlus } from 'react-icons/fi'
import ReactMarkdown from 'react-markdown'
import Button from './UI/Button'
import Card from './UI/Card'
import I18n from './UI/I18n'
import Price from './UI/Price'

export default function Option({ product, event, addProduct, removeProduct }) {
  const upcoming = event.stripeCache.upcoming
  const invoices = [...event.stripeCache.invoices, upcoming]
  // we use product to match and not price so we can update prices
  const invoice = invoices.find(i => i && i.lines.data.find(l => l.price.product === product.stripeCache.product.id))
  const active = product.active === undefined ? invoice : product.active
  const price = (active && invoice) ? invoice.lines.data.find(l => l.price.product === product.stripeCache.product.id).amount : product.stripeCache.unit_amount
  const click = () => {
    if (upcoming && upcoming.loading) return
    if (active) removeProduct(product) 
    else addProduct(product)
  }
  return (
    <Card
      className={`w-1/2+sm lg:w-1/4+m ${!active ? 'bg-white text-gray-500' : 'border-2 border-pink text-pink'}`}>
      <div className="flex justify-center w-full h-full">
        <div className="flex justify-center items-center text-center leading-tight p-1 flex-grow">
          <div className="text-sm">{product.name}</div>
        </div>
        <div
          onClick={click}
          className={`flex flex-col justify-center items-center w-16 -mr-2 -mt-2 -mb-2 ml-1 border-l border-gray-100 flex-shrink-0 flex-grow-0 hover:bg-pink hover:text-white cursor-pointer ${active && 'bg-pink text-white'}`}>
          {!active ? <FiPlus /> : <FiCheck />}
          <div className="text-xs font-bold">{price === 0 ? 'Offert' : <Price price={price} />}</div>
        </div>
      </div>
    </Card>
  )
}
