import React, { useEffect, useState } from 'react'
import { FiPenTool } from 'react-icons/fi'
import { DisableBodyScroll } from '../utils'
import Button from './UI/Button'
import Card from './UI/Card'

export default function Custo({ customization = [], saved }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const handler = event => {
      if (event.origin !== 'https://crea.wizito.com') return
      console.log(event)
      if (event.data?.event === 'saved') {
        setOpen(false)
        saved([event.data, customization[1]])
      }
      if (event.data?.event === 'exit')
        setOpen(false)
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])
  const pola = (customization || [])[0]
  const screen = (customization || [])[1]
  if (!pola || !pola.render)
    return (
      <>
        <Card
          headerClassName={`bg-white text-gray-400 border-b border-gray-100`}
          className={`w-1/3+sm lg:w-1/3+m `}
          header={
            <div className="flex items-center">
              <FiPenTool className="mr-3 w-6 h-6" />
              Personnalisation
            </div>
          }>
          <div className="flex flex-col h-full text-center leading-tight p-1">
            <div className="font-thin text-base flex-grow flex justify-center items-center text-gray-500">Vous pouvez modifier votre cadre photo jusqu'à 10 jours avant votre événement</div>
            <Button variant="secondary-white" className="mt-3 mx-auto" onClick={() => setOpen(true)}>Personnaliser</Button>
          </div>
        </Card>
        {open && pola &&
          <>
            <iframe src={`https://crea.wizito.com/project/${pola.id}_${pola.token}`} className="fixed bg-white top-0 left-0 w-screen h-screen z-50" />
            <DisableBodyScroll />
          </>
        }
      </>
    )
  return (
    <>
      <Card
        headerClassName={`bg-white text-gray-400 border-b border-gray-100`}
        className={`w-1/3+sm lg:w-1/3+m `}
        header={
          <div className="flex items-center">
            <FiPenTool className="mr-3 w-6 h-6" />
            Personnalisation
          </div>
        }>
        <div className="flex">
          {pola.render && <img src={pola.render.url} className="h-32 mr-2" />}
          <div className="flex flex-col justify-center items-center h-full text-center leading-tight p-1">
            <div className="font-thin text-base flex justify-center items-center text-gray-500">Votre personnalisation a bien été sauvegardée</div>
            <Button variant="secondary-white" className="mt-3 mx-auto" onClick={() => setOpen(true)}>Modifier</Button>
          </div>
        </div>
      </Card>
      {open && pola &&
        <>
          <iframe src={`https://crea.wizito.com/project/${pola.id}_${pola.token}`} className="fixed bg-white top-0 left-0 w-screen h-screen z-50" />
          <DisableBodyScroll />
        </>
      }
    </>
  )
}
